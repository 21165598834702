<template>
<Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
    <div class="height-div">
        <transition name="slide-fade">
            <div id="puntoyipi" class="flex-item-row offset" v-if="animation1"> 
                <p class="title-2"> Puntos Yipi</p>
        
                <p class="subtitle-6 width-parragraph" style="text-align:center;line-height:1.3"> Las  buenas prácticas son para repetirse,
                            adquiere tu <span class="green-weight"> punto Yipi</span> </p>
                            <DialogFormPuntos :dialog="dialog" :setDialog="setDialog"/>
                    <img @click="setDialog" class="mt-10 btn" src="../assets/320x480/Boton_Formulario.png"/>
            
            
                <picture >
                        <source media="(max-width: 700px)" srcset="../assets/animacion/10/Yipi_AnimacionWeb_10_Ipad.gif">
                        <img  loading="lazy" class="img-small" src="../assets/animacion/10/Yipi_AnimacionWeb_10.gif" alt="animation yipi">
                </picture>
            </div>
        </transition>
    </div>
</Intersect>
</template>

<script>
import DialogFormPuntos from "../components/DialogFormPuntoYipi.vue";
import Intersect from 'vue-intersect'
export default {
    
    data: ()=>({
        dialog:false,
        animation1:false
    }),
    methods:{
        setDialog(){
       this.dialog = !this.dialog;
        }
    },
    components:{
        DialogFormPuntos,
        Intersect
    }
}
</script>

